$lightGray : #e5e5e5;
$darkerGray : #757575;
$tackSwapBlue: #8ab4f8; 
$tackSwapGreen: #690375;
// $tackSwapRed: #E74C3C;
$tackSwapRed: #690375;
$tackSwapYellow: #FFD447;
$tackSwapBlack: #050404;

$boxShadowMain: 1px 1px 4px 0px rgba(0,0,0,0.35);
$boxShadowTile: 0px 1px 5px 0px rgba(0,0,0,0.25);

$buttonTransition: all 0.12s linear;

@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

@import '@fontsource/work-sans/400.css';
@import '@fontsource/work-sans/500.css';
@import '@fontsource/work-sans/600.css';
@import '@fontsource/work-sans/700.css';
@import '@fontsource/work-sans/800.css';
@import '@fontsource/work-sans/900.css';

@import '@fontsource-variable/grandstander/wght.css';
@import '@fontsource-variable/epilogue';

body, h4, h5{
  font-family: 'Epilogue Variable', sans-serif;
}

h1, h2, h3, .title {
  font-family: 'Grandstander Variable', sans-serif;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a{
  color:$tackSwapBlack;
  text-decoration: none;
  transition: $buttonTransition;
}

a:hover{
  color:$tackSwapBlue;
}

a:hover .price,
a:hover .salePrice{
  color: initial;
}

// NavLink Redo
.navButton,
.nav-link{
  padding: 0px 0.65rem;
  font-size: 1.3rem;
}

/* Scroller */
.scroller{
  width:100%;
  overflow-x: scroll;
  padding: 0px 0px 20px 10px;
  flex-wrap: nowrap;
  padding-right:0px;
  margin-left:0px;
  margin-right:0px;
}

.scrollerRightIndicator{
  background-color: var(--carousel-paddle-background-color, var(--color-background-primary));
  align-items: center;
  border: 1px solid;
  border-color: var(--carousel-paddle-border-color, var(--color-stroke-subtle));
  border-radius: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  display: flex;
  font-size: 18px;
  height: 32px;
  justify-content: center;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.45s ease-in-out;
  width: 32px;
  z-index: 1;
  right: 10%;
}

// Collection Tile
.collectionTile{
  background: white;
  border-radius: 4px;
  box-shadow: $boxShadowTile;
  width: 220px;
  width: 100%;
  margin-right: 10px;
}

.collectionTile.isGrid{
  width: 100%;
  margin-right: 0px;
  margin-bottom:10px;
}

.collectionTile .imgSection{
  padding: 10px;
  width: 100%;
  height: 100%;
}

.collectionTile .imgSection img{
  object-fit: cover;
  width: 100%;
  height: 200px;
  border-radius: 4px;
}

.collectionTextSection{
  padding-top: 20px;
}
.collectionTile .collectionTextSection{
  padding: 10px;
}
.collectionTile .collectionTextSection > *{
  margin-bottom: 4px;
}

// Product Tile
.productTile{
  background: white;
  border-radius: 4px;
  box-shadow: $boxShadowTile;
  height: 345px;
  width: 100%;
  margin-right: 10px;
}

.productTile.isGrid{
  width: 100%;
  margin-right: 0px;
  margin-bottom: 10px;
}

.productTile .imgSection{
  padding: 10px;
  width: 100%;
  height: 200px;
}

.productTile .imgSection img{
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

$textSectionTextSize : 0.9rem;
$textSectionTextSize_sm : 0.8rem;

.productCarouselImageWrapper{
  // overflow: scroll;
}
.productCarouselImageWrapper > *{
  position:sticky;
  top:15px;
  padding-bottom:10px;
}

.productTextSection{
  padding-top:20px;
}
.productTextSection .productTextHeader{
  display: flex;
}
.productTextSection .productTextHeader :first-child{
  flex:auto;
}
.productTextSection .productTextHeader :last-child{
  flex: initial;
}

.productTile .productTextSection{
  padding:10px;
}
.productTile .productTextSection > *{
  margin-bottom: 2px;
}

.productTile .productTextSection .title{
  font-size: 1rem;
  margin-bottom:1px;
  text-decoration: underline;
}

.productTile .productTextSection .condition{
  margin-bottom:0px;
}

.productTile .productTextSection .brand,
.productViewInfo .productTextSection .brand,
.productTile .productTextSection .condition,
.productViewInfo .productTextSection .condition{
  font-size:$textSectionTextSize_sm;
  color:$darkerGray;
}
.productViewInfo .productTextSection .brand,
.productViewInfo .productTextSection .condition{
  font-size:1rem;
}

.productTile .productTextSection .priceSection > *,
.productViewInfo .productTextSection .priceSection > *{
  display:inline-block;
  margin:0px;
  font-family: 'Epilogue Variable', sans-serif;
}
.productTile .productTextSection .priceSection > *:first-child,
.productViewInfo .productTextSection .priceSection > *:first-child{
  margin-right: 10px;
}

.productTile .productTextSection .price{
  font-size: $textSectionTextSize_sm;
}

.productTile .productTextSection .salePrice{
  font-size: $textSectionTextSize_sm;
}
.productTile .productTextSection .price.struck,
.productViewInfo .priceSection .price.struck{
  color:$darkerGray;
  text-decoration: line-through;
}
.productTile .productTextSection .price.struck{
  font-size: 0.7rem;
}

// Order Tile
.orderTile{
  background: white;
  border-radius: 4px;
  box-shadow: $boxShadowTile;
  // height: 345px;
  width: 100%;
  margin-right: 10px;
  padding:20px;
  display:flex;
  flex-direction: row;
}
.orderTile .orderTextSection{
  display: flex;
  flex-direction: column;
  flex:auto;
}
.orderTile .orderTextSection > *{
  margin-bottom:0px;
}
.orderTile .orderTextSection .id{
  font-size: 0.8rem;
  color: $darkerGray;
}
.orderTile .orderTextSection .title{
  text-decoration: underline;
  margin-bottom:4px;
}

.orderTile .orderTextSection .itemCount{
  font-size:0.9rem;
}

.orderTile .orderPriceSection{
  display:flex;
  flex:initial;
}


// SELLER

.sellerInfo{
  border: 1px solid $tackSwapBlack;
  border-radius: 4px;
  padding:10px;
  font-family: 'Epilogue Variable', sans-serif;
  margin:10px 0px;
  margin-bottom: 30px;
}

.sellerInfo .title{
  margin-bottom: 0px;
}

.sellerInfo .sellerName{
  text-decoration: underline;
}

.sellingPoints > * p{
  margin: 0px;
}

.sellingPoints{
  border-radius: 4px;
  padding: 20px 0px;
  margin: 10px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  border-top: 1px solid #c7c7c7;
  border-bottom: 1px solid #c7c7c7;
}

.sellingPoint{
  background: #f5f5f5;
  border-radius: 10px;
  padding:10px 10px;
  font-size:1.2rem;
  display: flex;
  align-items: center;
  gap: 20px;
  box-shadow: $boxShadowMain;
  width:100%;
}

.sellingPoint p{
  font-size:1rem;
  color:#000;
}

.sellingPoint .icon{
  background: $tackSwapGreen;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.additionalInfos{
  margin:10px 0px;
}

// Seller Setup
.setupView{
  display:flex;
  height:100vh;
  flex-direction:column;
}
.setUpStep{
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align:center;
}
.setUpStepContent{

}

#setupStepOne{
  background:$tackSwapGreen;
  color: #fff;
}

// Buttons
button{
  background: $tackSwapBlack;
  color:#fff;
  border:none;
  border-radius:4px;
}

.mainSearchButton{
  border-radius: 0px 4px 4px 0px;
  width:100%;
  transition: $buttonTransition;
  font-size: 1.2rem;
}
.mainSearchButton:hover{
  background: $tackSwapBlue;
  font-size: 1.4rem;
}

.categoriesButtonWrapper{
  display:inline-block;
  width:auto;
  padding:0px 10px 0px 0px;
}

.categoriesButton{
  border: 1px solid $tackSwapBlack;
  color: $tackSwapBlack;
  font-size: 0.85rem;
  background:transparent;
  width: 100%;
  padding: 10px 20px;
  transition:$buttonTransition;
}
.categoriesButton:hover{
  background:$tackSwapGreen;
  border: 1px solid $tackSwapGreen;
  color:#fff;
}

.btn{
  display: flex;
  justify-content: center;
  background:$tackSwapBlack;
  width:100%;
  align-items: center;
  text-align: center;
  color: #fff;
  font-family: 'Grandstander Variable', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 20px;
  margin-bottom: 10px;
  transition: $buttonTransition;
  box-shadow: $boxShadowMain;
}
.btn p{
  margin-bottom:0px;
  margin-top:5px;
}

.btn.variant,
.btn.variantRed{
  background: #fff;
  color: $tackSwapBlack;
}

.btn.variant2{
  background: $tackSwapBlue;
}

.btn.variantOutlined{
  background: transparent;
  color: $tackSwapBlack;
  border: 1px solid $tackSwapBlack;
  box-shadow: $boxShadowMain;
}

.btn:hover{
  background: $tackSwapBlue;
  color:#fff;
}
.btn.variant:hover{
  background: $tackSwapGreen;
  color:#fff;
}
.btn.variantOutlined:hover{
  background: $tackSwapBlack;
}
.btn.variantRed:hover{
  background: $tackSwapRed;
  color:#fff;
}
.btn.checkout{
  background:$tackSwapBlue;
  color:#fff;
}
.btn.checkout:hover{
  background:$tackSwapRed;
  color:#fff;
}

.circleBtnWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.circleBtn{
  background: $tackSwapBlack;
  border-radius: 50%;
  padding: 0px 0px;
  box-shadow: $boxShadowMain;
  width: 3rem;
  height: 3rem;
  display:flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  transition: $buttonTransition;
}
.circleBtn.sm{
  width: 2rem;
  height: 2rem;
}

.circleBtn.variant{
  background: #fff;
  color:$tackSwapBlack;
}

.circleBtn:hover{
  background: $tackSwapBlue;
  // font-size:1.4rem;
}

.circleBtn-textLabel{
  text-transform: uppercase;
  font-size: 0.65rem;
  margin: 0.75rem 0px 0px;
  text-decoration: none !important;
}
.circleBtn.favorite{
  background:#FFF;
  color:$tackSwapRed;
}
.circleBtn.isFavorited{
  background:$tackSwapRed;
  color:#FFF;
}
.circleBtn.largeTxt{
  font-size: 1.4rem;
}

.btn.sm{
  font-size: 0.7rem;
  height: auto;
  width: auto;
}

.smallButton{
  padding:5px 10px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap:5px;
}

.profileMenuButton{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width:100%;
  border-radius: 4px;
  box-shadow: $boxShadowMain;
  padding:20px 0px;
  text-align: center;
  margin-bottom: 15px;
}
.profileMenuButton span{
  font-size:1.5rem;
}

// SellerView
.sellerImgSection{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1.5rem;
}
.sellerDetailsSection{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: center;
  background: $lightGray;
  padding:0px 20px;
  border-radius: 4px;
}
.sellerDetailsSection > p{
  margin:0px;
}
.sellerImg{
  display:flex;
  width: 200px;
  height: 200px;
}

.sellerImg img{
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.sellerInfoSection{
  margin-bottom:20px;
}

// Cart
.cartItemsSection{
  margin: 30px 0px;
}
.cartSubtotal{
  // margin-top:10px;
  display: flex;
  
}
.cartSubtotal.final{
  padding-top: 15px;
  border-top: 1px solid $darkerGray;
  margin-top:5px;
}
.cartSubtotal.final h6{
  font-size:1.2rem;
}
.cartSubtotal > *{
  flex:initial;
}


.cartSubtotal > *:first-child{
  flex:auto;
}
.cartSubtotal.second{
  border-bottom: none;
  // border-top: 1px solid $darkerGray;
  padding-top: 20px;
  padding-bottom:0px;
}

.cartSubtotal.third{
  border-bottom: none;
  border-top: none;
  
  // padding-top: 20px;
}


.cartTile{
  background: white;
  border-radius: 4px;
  box-shadow: $boxShadowTile;
  // height: 200px;
  width: 100%;
  margin-right: 10px;
  margin-bottom: 12px;
  display: flex;
}

.cartTile .imgSection{
  padding: 10px;
  width:20%;
}

.cartTile .imgSection img{
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.cartTextSection{
  padding-top:20px;
}
.cartTile .productTextSection{
  padding:10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: auto;
}
.cartTile .productTextSection > *{
  margin-bottom: 2px;
}

.cartTile .productTextSection .title{
  font-size: 1.5rem;
  margin-bottom:1px;
  text-decoration: underline;
}

.cartTile .textSectionHeader{
  display:flex;
}
.cartTile .textSectionHeader > *:first-child{
  flex:auto;
  margin-bottom: 5px;
}

.cartTile .productTextSection .condition{
  margin-bottom:0px;
}

.cartTile .productTextSection .brand,
.cartTile .productTextSection .condition,
.cartTile .seller{
  font-size: 0.9rem;
  color:$darkerGray;
  margin:0px;
}
.cartTile .seller{
  font-size:1rem;
}
.productViewInfo .productTextSection .brand,
.productViewInfo .productTextSection .condition{
  font-size:1rem;
}

.cartTile .productTextSection .priceSection > *{
  display:inline-block;
  margin:0px;
  font-family: 'Epilogue Variable', sans-serif;
}
.cartTile .productTextSection .priceSection > *:first-child{
  margin-right: 10px;
  font-size:1.4rem;
}

.cartTile .productTextSection .price{
  font-size: $textSectionTextSize_sm;
}

.cartTile .productTextSection .salePrice{
  font-size: $textSectionTextSize_sm;
}
.cartTile .productTextSection .price.struck{
  color:$darkerGray;
  text-decoration: line-through;
}
.cartTile .productTextSection .price.struck{
  font-size: 0.7rem;
}
.cartTile .buttonSection{
  display:flex;
  flex-direction:row;
  gap:5px;
  // padding-top: 10px;
  justify-content: flex-end;
}
.cartTile .sellerSection{
  display:flex;
  align-items: center;
}
.cartTile .sellerSection > *:first-child{
  flex:auto;
}

// Sign In
.signInBox{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 50px;
  align-items: center;
  background:#fff;
  box-shadow: $boxShadowMain;
  width: 60%;
  border-radius:4px;
}
.signInBox span{
  text-decoration: underline;
}

.signInBox span:hover{
  color:$tackSwapBlue;
  cursor: pointer;
}

.signInRight,
.signUpRight{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Categories View
.headerRow{
  display:flex;
  flex-direction: row;
  // justify-content: end;
}
.headerRow > *:first-child{
  flex:auto;
  margin:0px;
}
.headerRow > button{
  flex:initial;
}

// Filter
.filterBody{
  display:flex;
  flex-direction: column;
  height: 100%;
}
.filterOptions{
  display: flex;
  flex-direction: column;
  flex:auto;
  gap:10px;
}
.filterBody .submitButton{
  display: flex;
  flex:initial;
}

// Sell View
.inputHeader{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.inputImageCarousel{
  height:400px;
}

.inputImageCarousel .carousel-item{
  height:400px;
}
.inputImageCarousel .inputCarouselImage{
  height:100%;
}

.inputImageBlock{
  border: dashed 1px $darkerGray;
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.inputImage{
  object-fit: cover;
  width: 100%;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  filter:invert(100%);
  // border-radius: 4px;
}

// Image Manager
.imageManagerBody{

}

.imageWrapper{
  position:relative;
}

.imageWrapper .image{
  width: 100%;
  border-radius: 4px;
  box-shadow: $boxShadowMain;
}

.imageWrapper .deleteImageButton{
  position: absolute;
  top: 0;
  right:0;
}

.categoriesWrapper{
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.categoriesWrapper .category{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px $darkerGray;
  cursor: pointer;
}

.bottomNav{
  position:absolute;
  bottom:0;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.ts-badge{
  position:absolute;
  top: -4px;
  right: -11px;
  background:$tackSwapRed;
  display:flex;
  justify-content: center;
  align-items: center;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 25px;
  color: #fff;
  font-size: 0.75rem;
  text-align: center;
}
.ts-badge p{
  font-family:"Roboto"; 
  margin:0px;
}

// Messages / Threads
.thread{
  display:flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0px;
  border-bottom: solid 1px $lightGray;
  cursor: pointer;
  gap: 15px;
}
.profileImgWrapper{
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  display:flex;
  justify-content: center;
  align-items: center;
}
.profileImgWrapper img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thread .threadContentWrapper{
  flex:auto;
  display: flex;
  justify-content: center;
}

.thread .threadContentWrapper .threadContent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex:auto;
}
.threadContent p,
.threadContent h5{
  margin:0px;
}
.threadContent h5{

}
.threadContent p{
  font-size:0.8rem;
  color:$darkerGray;
}

.messageMain{
  position: relative;
  flex-shrink: 1;
  flex-grow: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.messageHeader{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-bottom: solid 1px #e5e5e5;
  flex-basis: 0;
}

.messageHeader .backButton{
  padding-right: 10px;
}

.messageHeader h5{
  margin:0px;
}

.messagesBody{
  display: flex;
  // justify-content: flex-end;
  overflow-y: scroll;
  flex-direction: column;
  gap: 10px;
  position: relative;
  padding-top: 1.5rem !important;
  padding-bottom: 2rem !important;
  flex-basis: 0;
  max-height: inherit;
  flex-grow: 1;
  height: 100%;
}

.messageWrapper{
  display:flex;
}

.messageWrapper.sent{
  justify-content: flex-end;
}

.messageWrapper.received{
  justify-content: flex-start;
}

.messageWrapper.sent .messageWrap{
  align-items: flex-end;
}

.messageWrapper.received .messageWrap{
  align-items: flex-start;
}

.messageWrap{
  display:flex;
  flex-direction: column;
}

.messageWrap p,
.message p{
  margin:0px;
  display:flex;
}

.messageWrap .time{
  font-size:0.6rem;
  color:$darkerGray;
}
.message{
  font-size: 0.75rem;
  width: 80%;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  word-break: break-word;
  min-width: fit-content;
  max-width: 400px;
  max-width: 80vw;
}
#messagePageWrapper{
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.messageMainBody{
  display: flex;
  flex-direction: column;
}
.message.sent{
  background:$tackSwapBlue;
  border-radius: 15px 15px 0px 15px;
  justify-content: flex-end;
}
.message.received{
  background:$lightGray;
  border-radius: 15px 15px 15px 0px;
  justify-content: flex-start;
}

.messageInputWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  background: white;
}
.messageInput{
  width: 100%;
  padding-bottom: 20px;
  padding-top: 10px;
  position:relative;
}
.messageInput .sendMessage{
  padding-left: 10px;
  position: absolute;
  right: 8px;
  bottom: 32px;
}
.selectableUserList{
  display:flex;
  flex-direction: column;
  gap: 15px;
}

.selectableUser{
  display:flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 15px;
  border-bottom: solid 1px $lightGray;
  cursor: pointer;
  box-shadow: $boxShadowMain;
  border-radius:5px;
}
.selectableUser > *{
  margin:0px;
}

// MOBILE
// Only above phone
@media (min-width: 768px){
  .cartTile{
    height:175px;
  }
  .cartItemsSection{
    margin:0px;
  }
  // .cartSubtotal.second{
  //   border-top:none;
  // }
  .cartSubtotal.third,
  .cartSubtotal.second{
    padding:0px;
  }
  .cartCheckoutSection{
    border: 1px solid $darkerGray;
    display:flex;
    flex-direction: column;
    justify-content: center;
    height: 255px;
    gap:5px;
  }

  .scroller::after {
    content: "";
    display: block;
    position: sticky;
    right: 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white 100%);
    width: 10%;
    // margin-left: -19%;
    flex-shrink: 0;
  }
  .sellerInfo,
  .productTextSection .btn,
  .sellingPoints{
    // width:80%;
  }
}
// Only below Phone
@media (max-width: 768px){
  .messageInputWrapper{
    width:100%;
  }
  .cartTile .sellerSection{
    flex-direction: column;
    gap:10px;
    align-items: unset;
  }
  
  .cartTile .imgSection{
    width: 40%;
  }
  .cartTile .productTextSection .priceSection > *:first-child{
    font-size: 1.1rem;
  }
  .cartTile .productTextSection .brand, 
  .cartTile .productTextSection .condition,
  .cartTile .seller{
    font-size:0.8rem;
  }
  .cartTile .textSectionHeader .title{
    font-size:1.3rem;
  }
  .cartTile .buttonSection{
    justify-content: flex-start;
  }
  .btn.sm{
    font-size: 0.6rem;
    height: auto;
    width: auto;
  }
  .sellerImg{
    width: 150px;
    height: 150px;
    margin-bottom: 15px;
  }
  .sellerDetailsSection{
    align-items: center;
    padding:15px 0px;
  }
  .signInBox{
    width:90%;
  }
  .signInRight,
  .signUpRight{
    background:$tackSwapBlue;
  }
}