@import "../../App.scss";

.profileImgSection{
    display: flex;
    justify-content: center;
    flex-direction:column;
}
.profileImg{
    background:$tackSwapBlue;
    color:#FFF;
    padding: 35px 40px;
    border-radius:4px;
    font-size:1.8rem;
}
.profileMainHeader{
    background:$tackSwapGreen;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding: 10px 0px;
    border-radius: 4px;
    box-shadow: $boxShadowMain;
    margin-bottom: 15px;
}

.profileMainHeader > h1,
.profileMainHeader > h2{
    margin:0px;
}